import React, { useState } from 'react';

import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AccountBalance, AccountBalanceWallet, AccountBox, CalendarToday, CalendarViewDay, Description, Email, FileCopy, Fingerprint, FlipToBackOutlined, Map, OpenInBrowser, OpenInNew, Phone, ZoomOutMapOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import { updateDepositSuccess } from '../../store/setting/setting.actions';
import SettingAPI from '../../store/setting/setting.api';
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function DepositDetail(props) {
    const dispatch = useDispatch();
    const { deposit, tenants } = props;
    const [loading, setLoading] = useState(false);
    if (!deposit.type) {
        return <span />
    }

    const sendAdminCoins = async (id) => {
        setLoading(true);
        await SettingAPI.SendAdminTransaction("deposit", id).then((s) => {
            dispatch(updateDepositSuccess(s, s.id));
        }).finally(() => {
            setLoading(false);
            props.onClose();
        });

    }

    return (
        <div>
            <Dialog maxWidth="md" fullWidth onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Deposit Detail
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer ID' primary={deposit.cid} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.cid);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBox />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Name' primary={deposit.name} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.name);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Email />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Email' primary={deposit.email} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.email);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Phone />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Mobile' primary={deposit.mobile} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.mobile);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalance />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Coins' primary={`${deposit.tokenSymbol || deposit.type} ${deposit.coins}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.coins);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Charge' primary={`${deposit.tokenSymbol || deposit.type} ${deposit.transactionCharge}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.transactionCharge);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction ID' primary={deposit.id} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.id);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Description />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Reference #' primary={deposit.txnid} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.txnid);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Map />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='From IP Address' primary={deposit.domainName} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.domainName);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ZoomOutMapOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Status' primary={deposit.status} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(deposit.status);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Created On' primary={moment.unix(deposit.createdOn._seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(deposit.createdOn._seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarViewDay />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Last Modified On' primary={moment.unix(deposit.updatedOn._seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(deposit.updatedOn._seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Market Value' primary={deposit.marketValue + " USD"} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Deposit Address' primary={deposit.address} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(deposit.type) + deposit.address}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        {
                            deposit.tokenAddress &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FlipToBackOutlined />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Token Address' primary={deposit.tokenAddress} />
                                        {
                                            navigator && navigator.clipboard &&
                                            <ListItemSecondaryAction>
                                                <a target='_blank' href={getBlockChainUrl(deposit.type) + deposit.tokenAddress}>
                                                    <OpenInNew color="primary" />
                                                </a>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Hash' primary={deposit.transactionhash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(deposit.type) + deposit.transactionhash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Charge Hash' primary={deposit.parentTransactionHash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(deposit.type) + deposit.parentTransactionHash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Success Url' primary={deposit.successUrl} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={deposit.successUrl}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Failure Url' primary={deposit.failureUrl} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={deposit.failureUrl}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Webhook Url' primary={deposit.webhookUrl} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={deposit.webhookUrl}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        deposit.transactionhash && !deposit.parentTransactionHash ?
                            <Button disabled={loading} autoFocus onClick={() => sendAdminCoins(deposit.id)} color="primary">
                                Send Coins
                            </Button> : <></>
                    }
                    <Button autoFocus onClick={() => props.onClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
