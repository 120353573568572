import { call, put, takeEvery } from 'redux-saga/effects';
import UserAPI from './user.api';
import { UserActionTypes } from './user.model';
import { addUserFailure, addUserSuccess, deleteUserFailure, deleteUserSuccess, loadUserDetailFailure, loadUserDetailSuccess, loadUsersFailure, loadUsersSuccess, updateUserFailure, updateUserSuccess } from './user.actions';

export function* handleFetchUsers() {
  try {
    const response = yield call(UserAPI.LoadUsers);
    yield put(loadUsersSuccess(response));
  } catch (e) {
    yield put(loadUsersFailure(e));
  }
}

export function* handleFetchUser(action) {
  try {
    const response = yield call(UserAPI.LoadUserDetail, action.payload);
    yield put(loadUserDetailSuccess(response));
  } catch (e) {
    yield put(loadUserDetailFailure(e));
  }
}

export function* handleAddUser(action) {
  try {
    const response = yield call(UserAPI.AddUser, action.payload);
    yield put(addUserSuccess(response));
  } catch (e) {
    yield put(addUserFailure(e));
  }
}

export function* handleUpdateUser(action) {
  try {
    const response = yield call(UserAPI.UpdateUser, action.payload);
    yield put(updateUserSuccess(response));
  } catch (e) {
    yield put(updateUserFailure(e));
  }
}

export function* handleDeleteUser(action) {
  try {
    yield call(UserAPI.DeleteUser, action.payload);
    yield put(deleteUserSuccess(action.payload));
  } catch (e) {
    yield put(deleteUserFailure(e));
  }
}

export function* UserSaga() {
  yield takeEvery(UserActionTypes.FETCH_REQUEST, handleFetchUsers);
  yield takeEvery(UserActionTypes.ADD_REQUEST, handleAddUser);
  yield takeEvery(UserActionTypes.UPDATE_REQUEST, handleUpdateUser);
  yield takeEvery(UserActionTypes.DELETE_REQUEST, handleDeleteUser);
  yield takeEvery(UserActionTypes.DETAIL_REQUEST, handleFetchUser);
}

