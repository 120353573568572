import React, { useState } from 'react';

import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AccountBalance, AccountBalanceWallet, AccountBox, CalendarToday, CalendarViewDay, Description, Email, FileCopy, Fingerprint, Map, OpenInBrowser, OpenInNew, Phone, ZoomOutMapOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function SwapDetail(props) {
    const dispatch = useDispatch();
    const { swap, tenants } = props;
    const [loading, setLoading] = useState(false);
    if (!swap.coinType) {
        return <span />
    }

    return (
        <div>
            <Dialog maxWidth="lg" fullWidth onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Swap Detail
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Coins' primary={swap.transactionCoins + " " + swap.coinType} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBox />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Tokens' primary={swap.transactionTokens + " " + swap.tokenSymbol} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Coin Price' primary={swap.coinPriceUSD + " USD"} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBox />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Token Price' primary={swap.tokenPriceUSD + " USD"} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Email />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Email' primary={swap.userEmail} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.userEmail);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction Charge' primary={`${swap.transactionChargeCoins} ${swap.coinType} `} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.transactionChargeCoins);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Customer Address' primary={`${swap.custAddress}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(swap.coinType) + swap.custAddress}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Admin Address' primary={`${swap.adminAddress}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(swap.coinType) + swap.adminAddress}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Charge Address' primary={`${swap.superAdminAddress}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(swap.coinType) + swap.superAdminAddress}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWallet />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Token Address' primary={`${swap.tokenAddress}`} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(swap.coinType) + swap.tokenAddress}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Transaction ID' primary={swap.id} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.id);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Description />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Reference #' primary={swap.transactionId} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.transactionId);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Map />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='From IP Address' primary={swap.domainName} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.domainName);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ZoomOutMapOutlined />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Status' primary={swap.status} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(swap.status);
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Created On' primary={moment.unix(swap.createdOn._seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(swap.createdOn._seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarViewDay />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Last Modified On' primary={moment.unix(swap.updatedOn._seconds).format("lll")} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <FileCopy color="primary" onClick={() => {
                                                navigator.clipboard.writeText(moment.unix(swap.updatedOn._seconds).format("lll"));
                                                toastr.success("Copied to clipboard");
                                            }} />
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Sell Hash' primary={swap.spentHash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(swap.coinType) + swap.spentHash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Buy Hash' primary={swap.receiveHash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(swap.coinType) + swap.receiveHash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Fingerprint />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Charge Hash' primary={swap.transactionChargeHash} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getTransactionUrl(swap.coinType) + swap.transactionChargeHash}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List disablePadding>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <OpenInBrowser />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText secondary='Webhook Url' primary={swap.webhookUrl} />
                                    {
                                        navigator && navigator.clipboard &&
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={swap.webhookUrl}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
