import { AuthenticationActionTypes } from './auth.model';
import { action } from "typesafe-actions";

export const loginRequest = (req) => action(AuthenticationActionTypes.LOGIN_REQUEST, req);
export const loginSuccess = (res) => action(AuthenticationActionTypes.LOGIN_SUCCESS, res);
export const loginFailure = (err) => action(AuthenticationActionTypes.LOGIN_FAILURE, err);

export const logoutRequest = (req) => action(AuthenticationActionTypes.LOGIN_REQUEST, req);
export const logoutSuccess = (res) => action(AuthenticationActionTypes.LOGOUT_SUCCESS, res);
export const logoutFailure = (err) => action(AuthenticationActionTypes.LOGOUT_FAILURE, err);
