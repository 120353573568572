import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Avatar } from '@material-ui/core';
import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        color: '#FFFFFF',
        marginBottom: theme.spacing(2)
    },
    listItem: {
        padding: 0
    },
    calendarItem: {
        right: 0
    },
    leftIcon: {
        color: "#FFFFFF"
    },
    avatar: {
        backgroundColor: theme.palette.secondary.dark,
    }
}));

export default function TitleBar(props) {
    const classes = useStyles();
    return (
        <List className={classes.root}>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.leftIcon}>
                    <Avatar className={classes.avatar}>
                        <FontAwesomeIcon icon={faAnchor} />
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={props.heading}
                    secondary={props.secondary}
                    classes={{ secondary: classes.leftIcon }}
                />
                <ListItemSecondaryAction className={classes.calendarItem}>
                    {props.icon ? props.icon : <LiveHelpIcon />}
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
}