import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { getTransactionUrl } from '../../helpers/utility';
import _ from "underscore"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    icon: {
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: -10
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        background: theme.palette.info.main,
        color: 'white'
    }
}));


export default function DepositList(props) {
    const classes = useStyles();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = props.deposits.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => props.onDetailsOpen(user[0])}
                        />
                    );
                }
            }
        });
    };

    const hashRenderer = () => {
        return ({
            name: 'id',
            label: 'Transaction Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(props.deposits, s => s.id === value)
                    return (
                        item.transactionhash ? <a target='_blank' href={getTransactionUrl(item.type) + item.transactionhash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const chargeRenderer = () => {
        return ({
            name: 'id',
            label: 'Charge Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(props.deposits, s => s.id === value)
                    return (
                        item.parentTransactionHash ? <a target='_blank' href={getTransactionUrl(item.type) + item.parentTransactionHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                background: value === "Completed" ? "green"
                                    : value === "Admin Pending" ?
                                        "goldenrod" : value === "Rejected" ? "red" : "teal"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Transfered Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'transactionCharge',
            label: 'Transaction Charge',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        hashRenderer(),
        chargeRenderer(),
        statusRenderer(),
        actionRenderer()
    ];

    const tenants = {}

    props.users.forEach((x) => {
        tenants[x.id] = x.tenantName;
    })


    const data = props.deposits.map((item) => {
        return {
            id: item.id,
            createdDate: moment.unix(item.createdOn._seconds).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.type),
            coins: item.coins + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.type),
            status: item.transactionhash && item.parentTransactionHash ? "Completed" :
                item.transactionhash && !item.parentTransactionHash ? "Admin Pending" : item.status
            ,
            fromAddress: item.fromAddress,
            email: tenants[item.tenantId],
            hash: item.transactionhash,
            chargehash: item.parentTransactionHash
        };
    });

    return (
        <div className={classes.container}>
            <MUIDataTable
                title={'Deposit`s List'}
                data={data}
                columns={columns}
                options={{
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPageOptions: [10],
                }}
            />
        </div>
    );
}