import HttpHelper from "../../helpers/httpHelper";
import { IsNullOrEmpty } from "../../helpers/utility";
const headerInfo = {};
const limit = 1000;
class SettingAPI {
  static async LoadSetting() {
    let url = process.env.REACT_APP_API_URL + "/setting";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async UpdateSetting(data) {
    let url = process.env.REACT_APP_API_URL + "/setting";
    return HttpHelper.httpRequest(url, "PUT", headerInfo, data);
  }

  static async SendAdminTransaction(type, id) {
    let url =
      process.env.REACT_APP_API_URL + "/transactions/" + type + "/" + id;
    return HttpHelper.httpRequest(url, "PATCH", headerInfo);
  }

  static async LoadAdminSetting() {
    let url = process.env.REACT_APP_API_URL + "/adminsetting";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async LoadRecentTransactions() {
    let url = process.env.REACT_APP_API_URL + "/transactions/recent";
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async UpdateRecentTransactions(id) {
    let url = process.env.REACT_APP_API_URL + "/transactions/reinstant/" + id;
    return HttpHelper.httpRequest(url, "PATCH", headerInfo);
  }

  static async FetchTransactions(options) {
    const { type, uid, startBy } = options;

    let url = process.env.REACT_APP_API_URL + `/transactions?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async FetchDeposits(options) {
    const { type, uid, startBy } = options;
    let url = process.env.REACT_APP_API_URL + `/deposits?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
      url = url + `&startAt=${startBy}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async FetchWhitelists(options) {
    const { type, uid, startBy } = options;
    let url = process.env.REACT_APP_API_URL + `/whitelists?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
      url = url + `&startAt=${startBy}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async FetchSwaps(options) {
    const { type, uid, startBy } = options;
    let url = process.env.REACT_APP_API_URL + `/swaps?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
      url = url + `&startAt=${startBy}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }

  static async FetchWithdraws(options) {
    const { type, uid, startBy } = options;

    let url = process.env.REACT_APP_API_URL + `/requests?mode=view`;
    if (!IsNullOrEmpty(type)) {
      url = url + `&type=${type}`;
    }
    if (!IsNullOrEmpty(uid)) {
      url = url + `&id=${uid}`;
    }
    if (!IsNullOrEmpty(startBy)) {
      url = url + `&limit=${limit}`;
      url = url + `&startAt=${startBy}`;
    }
    return HttpHelper.httpRequest(url, "GET", headerInfo);
  }
}

export default SettingAPI;
