import axios from 'axios';
import '../assets/css/toastr.min.css';
import { getHeaderDetail } from './utility';
import swal from "sweetalert2";

export default class HttpHelper {
    static httpRequest = async (
        url,
        methodType,
        headers,
        body
    ) => {
        headers = getHeaderDetail(headers);

        let config = {
            method: methodType,
            url: url,
            headers: headers,
            data: body,
            raxConfig: {
                retry: 3,
                noResponseRetries: 2,
                retryDelay: 100,
                httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
                statusCodesToRetry: [[100, 199], [429, 429], [500, 599]]
            }
        };

        return await axios(config)
            .then(function (response) {
                return HttpHelper.successHandler(response);
            })
            .catch(function (error) {
                HttpHelper.errorHandler(error);
            });
    };

    static successHandler = (response) => {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        else {
            swal.fire(({
                title: "Error Occured",
                text: "Something Went Wrong",
                icon: "error"
            }))
        }
    };

    static errorHandler = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    swal.fire(({
                        title: error.response.data.code,
                        text: error.response.data.message,
                        icon: "error"
                    }))
                    break;
                case 401:
                    swal.fire(({
                        title: "Authorization Error",
                        text: "Session Expired or invalid login credentials entered",
                        icon: "error"
                    })).then((x) => {
                        if (x.isConfirmed) {
                            sessionStorage.removeItem("x-wallet-payment");
                            document.location.href = process.env.PUBLIC_URL;
                        }
                    })

                    break;
                case 404:
                    swal.fire(({
                        title: "Not Found",
                        text: "Requested resource does not exist",
                        icon: "error"
                    }))
                    break;
                case 409:
                    swal.fire(({
                        title: "Conflict",
                        text: "Version control conflit",
                        icon: "error"
                    }))
                    break;
                case 500:
                    swal.fire(({
                        title: "Error",
                        text: "Internal server error",
                        icon: "error"
                    }))
                    break;
                default:
                    swal.fire(({
                        title: "Error Occured",
                        text: "Problem with response",
                        icon: "error"
                    }))
                    break;
            }
        }
        else if (error.request) {
            swal.fire(({
                title: "Error Occured",
                text: "Problem with request",
                icon: "error"
            }))
        }
        else {
            swal.fire(({
                title: "Error Occured",
                text: error,
                icon: "error"
            }))
        }

        throw error;
    };
}
