import { SettingActionTypes } from './setting.model';
import { action } from "typesafe-actions";

export const loadSettingRequest = (req) => action(SettingActionTypes.FETCH_SETTING_REQUEST, req);
export const loadSettingSuccess = (res) => action(SettingActionTypes.FETCH_SETTING_SUCCESS, res);
export const loadSettingFailure = (err) => action(SettingActionTypes.FETCH_SETTING_FAILURE, err);

export const updateSettingRequest = (req) => action(SettingActionTypes.UPDATE_SETTING_REQUEST, req);
export const updateSettingSuccess = (res) => action(SettingActionTypes.UPDATE_SETTING_SUCCESS, res);
export const updateSettingFailure = (err) => action(SettingActionTypes.UPDATE_SETTING_FAILURE, err);

export const loadAdminRequest = (req) => action(SettingActionTypes.FETCH_ADMIN_REQUEST, req);
export const loadAdminSuccess = (res) => action(SettingActionTypes.FETCH_ADMIN_SUCCESS, res);
export const loadAdminFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const fetchRecentRequest = (req) => action(SettingActionTypes.FETCH_RECENTS_REQUEST, req);
export const fetchRecentSuccess = (res) => action(SettingActionTypes.FETCH_RECENTS_SUCCESS, res);
export const fetchRecentFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const loadTransactionsRequest = (type, uid, startBy) => action(SettingActionTypes.FETCH_TRANSACTIONS_REQUEST, {
    type, uid, startBy
});
export const loadTransactionsSuccess = (res) => action(SettingActionTypes.FETCH_TRANSACTIONS_SUCCESS, res);
export const loadTransactionsFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const loadDepositsRequest = (type, uid, startBy) => action(SettingActionTypes.FETCH_DEPOSITS_REQUEST, {
    type, uid, startBy
});
export const loadDepositsSuccess = (res) => action(SettingActionTypes.FETCH_DEPOSITS_SUCCESS, res);
export const loadDepositsFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const loadWhitelistsRequest = (type, uid, startBy) => action(SettingActionTypes.FETCH_WHITELISTS_REQUEST, {
    type, uid, startBy
});
export const loadWhitelistsSuccess = (res) => action(SettingActionTypes.FETCH_WHITELISTS_SUCCESS, res);
export const loadWhitelistsFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const loadWithdrawsRequest = (type, uid, startBy) => action(SettingActionTypes.FETCH_WITHDRAWS_REQUEST, {
    type, uid, startBy
});

export const loadSwapsSuccess = (res) => action(SettingActionTypes.FETCH_SWAPS_SUCCESS, res);
export const loadSwapsFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const loadSwapsRequest = (type, uid, startBy) => action(SettingActionTypes.FETCH_SWAPS_REQUEST, {
    type, uid, startBy
});

export const loadWithdrawsSuccess = (res) => action(SettingActionTypes.FETCH_WITHDRAWS_SUCCESS, res);
export const loadWithdrawsFailure = (err) => action(SettingActionTypes.FETCH_ERROR, err);

export const updateDepositSuccess = (req) => action(SettingActionTypes.UPDATE_DEPOSITS_SUCCESS, req);
export const updateWithdrawSuccess = (req) => action(SettingActionTypes.UPDATE_WITHDRAW_SUCCESS, req);


