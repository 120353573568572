import { Button, Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import request from 'superagent';
import HttpHelper from '../../helpers/httpHelper';
import { convertToSlug, IsNullOrEmpty } from '../../helpers/utility';
import toastr from 'toastr';

export default function FileDrop(props) {

    const [files, setFiles] = React.useState([]);
    const [fileData, setFileData] = React.useState([]);
    const handleChange = (files) => {
        setFiles(files);
        let data = files.map((x) => {
            return {
                id: null,
                value: null
            }
        })

        setFileData(data);
    };

    const upload = async () => {

        await Promise.all(
            files.map(async (file, index) => {
                let uploadHeader = {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT',
                    'x-ms-blob-type': ' BlockBlob'
                };
                let fileName = convertToSlug(fileData[index].id) + '-' + convertToSlug(fileData[index].value) + '.' + file.name.split('.').pop();
                let url = process.env.REACT_APP_API_URL + '/upload?containerName=' + props.containerName + '&blobName=' + fileName;
                return await HttpHelper.httpRequest(url, 'GET', {})
                    .then(async (SasUrl) => {
                        return await request
                            .put(SasUrl.url)
                            .accept('application/json')
                            .set(uploadHeader)
                            .send(file)
                            .on('progress', ({ percent }) => {
                                console.log(percent);
                            })
                            .catch((ex) => {
                                toastr.error('Error occured while uploading image', 'Upload Error')
                            })
                            .then((res) => {
                                return {
                                    url: SasUrl.url.split("?")[0],
                                    value: fileData[index].value,
                                    id: fileData[index].id
                                }
                            })
                    })
            })).then((response) => {
                toastr.success('Files Uploaded Successfully', 'Upload Successful');
                props.onUpload(response);
            });
    };

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '100%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        centerIcon: {
            fontSize: 40
        },
        table: {
            width: '100%',
            textAlign: 'left'
        },
        address: {
            lineHeight: 2,
            paddingLeft: theme.spacing(2)
        },
        title: {
            position: 'absolute',
            left: theme.spacing(2),
        },
        product: {
            backgroundColor: theme.palette.info.main,
            color: '#FFFFFF'
        },
        avathar: {
            backgroundColor: theme.palette.info.main,
            color: '#FFFFFF'
        },
        item: {
            margin: 10,
            float: "right"
        }
    }));
    const classes = useStyles();

    let isValid = fileData.filter((x) => {
        return !IsNullOrEmpty(x.id) && !IsNullOrEmpty(x.value)
    }).length === fileData.length;

    return (
        <Grid container spacing={2}>
            <Grid item md={6}>
                <DropzoneArea
                    onChange={handleChange}
                    showFileNames={true}
                    showPreviewsInDropzone
                    filesLimit={25}
                    acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
                />
            </Grid>
            <Grid item md={6}>
                <table id="product">
                    <thead>
                        <tr>
                            <th>Document</th>
                            <th>Type</th>
                            <th>ID #</th>
                        </tr>
                    </thead>
                    {
                        files.map((item, index) => {
                            return <tr>
                                <td>{item.name}</td>
                                <td>{
                                    props.items.length === 0 ?
                                        <TextField variant="outlined" margin="dense" />
                                        :
                                        <FormControl className={classes.formControl} variant="outlined">
                                            <Select
                                                margin="dense"
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={fileData[index].id}
                                                onChange={(val) => {
                                                    val = val.target.value;
                                                    let updatedFiles = fileData.map((x, curIndex) => {
                                                        if (index === curIndex) {
                                                            x.id = val;
                                                        }

                                                        return x;
                                                    })
                                                    setFileData(updatedFiles);
                                                }}
                                            >
                                                <MenuItem value={null}>Select Type</MenuItem>
                                                {
                                                    props.items.map((x) => <MenuItem value={x}>{x}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                }

                                </td>
                                <td><TextField variant="outlined" margin="dense"
                                    value={fileData[index].value}
                                    onChange={(val) => {
                                        val = val.target.value;
                                        let updatedFiles = fileData.map((x, curIndex) => {
                                            if (index === curIndex) {
                                                x.value = val;
                                            }

                                            return x;
                                        })
                                        setFileData(updatedFiles);
                                    }}

                                /></td >
                            </tr>;
                        })
                    }
                </table>
                <br />
                {
                    files.length > 0 && props.canUpload ?
                        <Button variant="outlined"
                            disabled={!isValid}
                            onClick={() => upload()}
                            className="float-right" >
                            Upload
                        </Button>
                        : undefined
                }
            </Grid>
        </Grid >
    );
}
