import { Avatar, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { decodeToken, IsNullOrEmpty, validEmail, validPhone, validURL } from '../../helpers/utility';
import FileDrop from '../base/FileDrop';
var countryjson = require('../../assets/country.json');

export default function AddUser(props) {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            width: '100%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        centerIcon: {
            fontSize: 40
        },
        table: {
            width: '100%',
            textAlign: 'left'
        },
        address: {
            lineHeight: 2,
            paddingLeft: theme.spacing(2)
        },
        title: {
            position: 'absolute',
            left: theme.spacing(2),
        },
        product: {
            backgroundColor: theme.palette.info.main,
            color: '#FFFFFF'
        },
        avathar: {
            backgroundColor: theme.palette.info.main,
            color: '#FFFFFF'
        },
        item: {
            margin: 10,
            float: "right"
        }
    }));
    const classes = useStyles();

    const [info, setInfo] = React.useState({
        tenantName: null,
        tenantWebsite: null,
        tenantPhone: null,
        address: null,
        country: undefined,
        region: undefined,
        zipCode: null,
        city: null,
        files: [],
        isAgreed: false,
        primaryContactName: null,
        primaryContactEmail: null,
        primaryContactPhone: null,
        secondaryContactName: null,
        secondaryContactEmail: null,
        secondaryContactPhone: null,
        formSubmitted: false
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        setInfo({ ...info, [name]: value });
    };

    const isValid = () => {
        return !IsNullOrEmpty(info.tenantName) &&
            !IsNullOrEmpty(info.tenantWebsite) &&
            !IsNullOrEmpty(info.tenantPhone) &&
            !IsNullOrEmpty(info.address) &&
            !IsNullOrEmpty(info.country) &&
            !IsNullOrEmpty(info.region) &&
            !IsNullOrEmpty(info.city) &&
            !IsNullOrEmpty(info.zipCode) &&
            !IsNullOrEmpty(info.primaryContactName) &&
            !IsNullOrEmpty(info.primaryContactEmail) &&
            !IsNullOrEmpty(info.primaryContactPhone) &&
            !IsNullOrEmpty(info.secondaryContactName) &&
            !IsNullOrEmpty(info.secondaryContactEmail) &&
            !IsNullOrEmpty(info.secondaryContactPhone) &&
            info.files.length > 0 &&
            info.isAgreed
    }

    const saveInfo = () => {
        if (isValid()) {
            let data = {
                tenantName: info.tenantName,
                tenantWebsite: info.tenantWebsite,
                tenantPhone: info.tenantPhone,
                address: info.address,
                country: info.country,
                region: info.region,
                zipCode: info.zipCode,
                city: info.city,
                documents: info.files,
                primaryContactName: info.primaryContactName,
                primaryContactEmail: info.primaryContactEmail,
                primaryContactPhone: info.primaryContactPhone,
                secondaryContactName: info.secondaryContactName,
                secondaryContactEmail: info.secondaryContactEmail,
                secondaryContactPhone: info.secondaryContactPhone,
            }
            props.onSave(data);
        }
        else {
            setInfo({ ...info, formSubmitted: true });
        }
    };

    const handleCheckChange = (event) => {
        setInfo({ ...info, isAgreed: event.target.checked });
    };


    let countryList = countryjson.countries.map((x) => {
        return x.country;
    });

    let stateList = [];

    if (!IsNullOrEmpty(info.country)) {
        let temp = countryjson.countries.filter((x) => {
            return x.country === info.country;
        });
        stateList = temp[0].states;
    }
    const preventDefault = (event) => event.preventDefault();
    return (
        <Dialog
            className='model-left-position'
            open={props.open}
            maxWidth='lg'
            onClose={() => props.onClose()}
            TransitionComponent={Slide}
            aria-labelledby="form-dialog-title"
            transitionDuration={500}
            TransitionProps={{
                direction: 'right'
            }}
        >
            <DialogTitle id="alert-dialog-slide-title"
            >
                Add New Customer
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Tenant Name"
                            fullWidth
                            error={info.formSubmitted && IsNullOrEmpty(info.tenantName)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.tenantName) ? 'Tenant Name Cannot be Empty' : ''}
                            name="tenantName"
                            value={info.tenantName}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Tenant Domian"
                            error={info.formSubmitted && !validURL(info.tenantWebsite)}
                            helperText={info.formSubmitted && !validURL(info.tenantWebsite) ? 'Tenant Website Name is not valid' : ''}
                            fullWidth name="tenantWebsite"
                            value={info.tenantWebsite}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Tenant Contact #"
                            error={info.formSubmitted && !validPhone(info.tenantPhone)}
                            helperText={info.formSubmitted && !validPhone(info.tenantPhone) ? 'Tenant Contact # is not valid' : ''}
                            fullWidth name="tenantPhone"
                            value={info.tenantPhone}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Address"
                            error={info.formSubmitted && IsNullOrEmpty(info.address)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.address) ? 'Address Cannot be Empty' : ''}
                            fullWidth
                            multiline
                            rows={6}
                            name="address"
                            value={info.address}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <FormControl className={classes.formControl}>
                            <FormLabel component="legend">Country</FormLabel>
                            <Select
                                name="country"
                                value={info.country}
                                onChange={handleInputChange}
                                error={info.formSubmitted && IsNullOrEmpty(info.country)}
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                            >
                                <MenuItem value={undefined}>Select Country</MenuItem>
                                {
                                    countryList.map((x) => {
                                        return <MenuItem value={x}>{x}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText error={info.formSubmitted && IsNullOrEmpty(info.country)}>
                                {info.formSubmitted && IsNullOrEmpty(info.country) ? 'Country Cannot be Empty' : ''}
                            </FormHelperText>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl className={classes.formControl}>
                            <FormLabel component="legend">Region</FormLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                error={info.formSubmitted && IsNullOrEmpty(info.region)}
                                id="demo-simple-select"
                                name="region"
                                value={info.region}
                                onChange={handleInputChange}
                            >
                                <MenuItem value={undefined}>Select Region</MenuItem>
                                {
                                    stateList.map((x) => {
                                        return <MenuItem value={x}>{x}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText error={info.formSubmitted && IsNullOrEmpty(info.region)}>
                                {info.formSubmitted && IsNullOrEmpty(info.region) ? 'Region Cannot be Empty' : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="City"
                            error={info.formSubmitted && IsNullOrEmpty(info.city)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.city) ? 'City Should not be Empty' : ''}
                            fullWidth
                            name="city"
                            value={info.city}
                            onChange={handleInputChange}
                        />
                        <br />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Zip Code"
                            error={info.formSubmitted && IsNullOrEmpty(info.zipCode)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.zipCode) ? 'Zipcode Cannot be Empty' : ''}
                            fullWidth
                            name="zipCode"
                            value={info.zipCode}
                            onChange={handleInputChange} />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Primary Contact Name"
                            error={info.formSubmitted && IsNullOrEmpty(info.primaryContactName)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.primaryContactName) ? 'Name Cannot be Empty' : ''}
                            fullWidth
                            name="primaryContactName"
                            value={info.primaryContactName}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Primary Contact Email"
                            error={info.formSubmitted && !validEmail(info.primaryContactEmail)}
                            helperText={info.formSubmitted && !validEmail(info.primaryContactEmail) ? 'Email is not valid' : ''}
                            fullWidth
                            name="primaryContactEmail"
                            value={info.primaryContactEmail}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Primary Contact Phone"
                            error={info.formSubmitted && !validPhone(info.primaryContactPhone)}
                            helperText={info.formSubmitted && !validPhone(info.primaryContactPhone) ? 'Phone # is not valid' : ''}
                            fullWidth
                            name="primaryContactPhone"
                            value={info.primaryContactPhone}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Secondary Contact Name"
                            error={info.formSubmitted && IsNullOrEmpty(info.secondaryContactName)}
                            helperText={info.formSubmitted && IsNullOrEmpty(info.secondaryContactName) ? 'Name Cannot be Empty' : ''}
                            fullWidth
                            name="secondaryContactName"
                            value={info.secondaryContactName}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Secondary Contact Email"
                            error={info.formSubmitted && !validEmail(info.secondaryContactEmail)}
                            helperText={info.formSubmitted && !validEmail(info.secondaryContactEmail) ? 'Email is not valid' : ''}
                            fullWidth
                            name="secondaryContactEmail"
                            value={info.secondaryContactEmail}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            label="Secondary Contact Phone"
                            error={info.formSubmitted && !validPhone(info.secondaryContactPhone)}
                            helperText={info.formSubmitted && !validPhone(info.secondaryContactPhone) ? 'Phone # is not valid' : ''}
                            fullWidth
                            name="secondaryContactPhone"
                            value={info.secondaryContactPhone}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    {
                        info.files.length === 0 ?
                            <Grid item md={12}>
                                <Typography variant="p" component="b">
                                    Upload Documents
                                </Typography>
                                <br />
                                <br />
                                <FileDrop
                                    containerName="kyc"
                                    canUpload={info.isAgreed}
                                    onUpload={(files) => setInfo({ ...info, files: files })}
                                    items={["PAN Card", "Aadhar Card", "Voter Id", "Driving License", "Cancelled Cheque", "Bank Passbook", "Passport", "Contract Document", "Company's PAN"]}
                                />
                            </Grid>
                            :
                            <List>
                                {
                                    info.files.map((x) => {
                                        return <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AssignmentIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={x.value}
                                                secondary={x.id}
                                            />
                                            <ListItemSecondaryAction>
                                                <Link href={x.url} rel="noopener noreferrer"  target="_blank" onClick={preventDefault}>
                                                    <IconButton edge="end" aria-label="delete">
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </Link>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })
                                }
                            </List>
                    }
                    <Grid item md={12}>
                        <FormControlLabel
                            control={<Checkbox name="isAgreed" onChange={handleCheckChange} />}
                            label={"I Acknowledge above original documents are verified by " + decodeToken()["email"]}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => saveInfo()} variant="contained" color="primary">
                    Save
                </Button>
                <Button onClick={() => props.onClose()} variant="outlined" color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    );
}