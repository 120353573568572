import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { getTransactionUrl } from '../../helpers/utility';
import _ from "underscore"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    icon: {
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: -10
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        background: theme.palette.info.main,
        color: 'white'
    }
}));


export default function SwapList(props) {
    const classes = useStyles();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = props.swaps.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => props.onDetailsOpen(user[0])}
                        />
                    );
                }
            }
        });
    };

    const sellRenderer = () => {
        return ({
            name: 'id',
            label: 'Sell Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(props.swaps, s => s.id === value)
                    return (
                        item.spentHash ? <a target='_blank' href={getTransactionUrl(item.coinType) + item.spentHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const buyRenderer = () => {
        return ({
            name: 'id',
            label: 'Buy Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(props.swaps, s => s.id === value)
                    return (
                        item.receiveHash ? <a target='_blank' href={getTransactionUrl(item.coinType) + item.receiveHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const chargeRenderer = () => {
        return ({
            name: 'id',
            label: 'Charge Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(props.swaps, s => s.id === value)
                    return (
                        item.transactionChargeHash ? <a target='_blank' href={getTransactionUrl(item.coinType) + item.transactionChargeHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
       
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                background: value === "Approved" ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Transfered Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'tokens',
            label: 'Transfered Tokens',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        sellRenderer(),
        buyRenderer(),
        chargeRenderer(),
        statusRenderer(),
        actionRenderer()
    ];

    const tenants = {}

    props.users.forEach((x) => {
        tenants[x.id] = x.tenantName;
    })


    const data = props.swaps.map((item) => {
        return {
            id: item.id,
            createdDate: moment.unix(item.createdOn._seconds).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.type),
            coins: item.transactionCoins + ' ' + item.coinType,
            tokens: item.transactionTokens + ' ' + item.tokenSymbol,
            status: item.status,
            fromAddress: item.fromAddress,
            email: tenants[item.tenantId],
            hash: item.transactionhash,
            chargehash: item.parentTransactionHash
        };
    });

    return (
        <div className={classes.container}>
            <MUIDataTable
                title={'Swap`s List'}
                data={data}
                columns={columns}
                options={{
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPageOptions: [10],
                }}
            />
        </div>
    );
}