import HttpHelper from '../../helpers/httpHelper';
const headerInfo = {};
class UserAPI {
    static async LoadUsers() {
        let url = process.env.REACT_APP_API_URL + '/customers';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async LoadUserDetail(id) {
        let url = process.env.REACT_APP_API_URL + '/customers/' + id;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static AddUser(request) {
        let url = process.env.REACT_APP_API_URL + '/customers';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, request);
    }

    static UpdateUser(request) {
        let url = process.env.REACT_APP_API_URL + '/customers/';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, request);
    }

    static DeleteUser(id) {
        let url = process.env.REACT_APP_API_URL + '/customers/' + id;
        return HttpHelper.httpRequest(url, 'DELETE', headerInfo);
    }
}

export default UserAPI;
