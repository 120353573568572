import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';
import _ from "underscore"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    icon: {
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: -10
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        background: theme.palette.info.main,
        color: 'white'
    }
}));


export default function WhitelistList(props) {
    const classes = useStyles();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = props.whitelists.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => props.onDetailsOpen(user[0])}
                        />
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                background: value === "Activated" ? "green" :
                                    value === "Cooling" ? "Goldenrod" : "Red"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'id',
            label: 'Address',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let user = _.find(props.whitelists, item => item.id === value);
                    return (
                        <a target='_blank' className='text-decoration-none' href={getBlockChainUrl(user.coinType) + user.address}>{user.address}</a>
                    );
                }
            }
        },
        {
            name: 'minCoins',
            label: 'Min Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'maxCoins',
            label: 'Max Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        statusRenderer(),
        {
            name: 'activatedAt',
            label: 'Activate At',
            options: {
                filter: false,
                sort: true,
            }
        },
        actionRenderer()
    ];

    const data = props.whitelists.map((item) => {
        let x = !item.coolingPeriod || item.coolingPeriod == -1 ?
            moment.unix(item.createdOn._seconds).format("lll") :
            moment.unix(item.createdOn._seconds).add(item.coolingPeriod, "minutes").format("lll");
        return {
            id: item.id,
            createdDate: moment.unix(item.createdOn._seconds).format("lll"),
            minCoins: item.minCoins + ' ' + item.coinType,
            status: item.isActive ? moment(x).diff(moment(), "minutes") > 0 ? "Cooling" : "Activated" : "Blocked",
            maxCoins: item.maxCoins + ' ' + item.coinType,
            coinType: item.coinType,
            address: item.address,
            activatedAt: x
        };
    });

    return (
        <div className={classes.container}>
            <MUIDataTable
                title={'Whitelist`s List'}
                data={data}
                columns={columns}
                options={{
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPageOptions: [10],
                }}
            />
        </div>
    );
}