import { Button, Container, Grid, TextField } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IsNullOrEmpty } from '../../helpers/utility';
import * as SettingActions from '../../store/setting/setting.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';

export default function SettingContainer(props) {
    const dispatch = useDispatch();
    const { setting } = useSelector((state) => {
        return {
            setting: state.SettingReducer.setting
        }
    });
    const [BCHCommision, setBCHCommision] = useState(undefined);
    const [BCHMin, setBCHMin] = useState(undefined);
    const [BTCCommision, setBTCCommision] = useState(undefined);
    const [BTCMin, setBTCMin] = useState(undefined);
    const [LTCCommision, setLTCCommision] = useState(undefined);
    const [LTCMin, setLTCMin] = useState(undefined);
    const [TRXCommision, setTRXCommision] = useState(undefined);
    const [TRXMin, setTRXMin] = useState(undefined);
    const [XRPCommision, setXRPCommision] = useState(undefined);
    const [XRPMin, setXRPMin] = useState(undefined);
    const [ETHCommision, setETHCommision] = useState(undefined);
    const [ETHMin, setETHMin] = useState(undefined);
    const [BNBCommision, setBNBCommision] = useState(undefined);
    const [BNBMin, setBNBMin] = useState(undefined);
    const [WithdrawCommision, setWithdrawCommision] = useState(undefined);
    const [DepositCommision, setDepositCommision] = useState(undefined);

    useEffect(() => {
        dispatch(SettingActions.loadSettingRequest())
    }, []);

    useEffect(() => {
        if (setting.id) {
            setBCHCommision(setting.BCHCommision);
            setBCHMin(setting.BCHMin);
            setBTCCommision(setting.BTCCommision);
            setBTCMin(setting.BTCMin);
            setLTCCommision(setting.LTCCommision);
            setLTCMin(setting.LTCMin);
            setTRXCommision(setting.TRXCommision);
            setTRXMin(setting.TRXMin);
            setXRPCommision(setting.XRPCommision);
            setXRPMin(setting.XRPMin);
            setETHCommision(setting.ETHCommision);
            setETHMin(setting.ETHMin);
            setBNBCommision(setting.BNBCommision);
            setBNBMin(setting.BNBMin);
            setWithdrawCommision(setting.WithdrawCommision);
            setDepositCommision(setting.DepositCommision)
        }
    }, [setting]);

    const save = () => {
        if(isValid()){
            let updatedSetting = setting;
            updatedSetting.BCHCommision = parseFloat(BCHCommision);
            updatedSetting.BTCCommision = parseFloat(BTCCommision);
            updatedSetting.LTCCommision = parseFloat(LTCCommision);
            updatedSetting.ETHCommision = parseFloat(ETHCommision);
            updatedSetting.TRXCommision = parseFloat(TRXCommision);
            updatedSetting.BNBCommision = parseFloat(BNBCommision);
            updatedSetting.XRPCommision = parseFloat(XRPCommision);
            updatedSetting.BCHMin = parseFloat(BCHMin);
            updatedSetting.BTCMin = parseFloat(BTCMin);
            updatedSetting.LTCMin = parseFloat(LTCMin);
            updatedSetting.ETHMin = parseFloat(ETHMin);
            updatedSetting.TRXMin = parseFloat(TRXMin);
            updatedSetting.BNBMin = parseFloat(BNBMin);
            updatedSetting.XRPMin = parseFloat(XRPMin);
            updatedSetting.WithdrawCommision = parseFloat(WithdrawCommision);
            updatedSetting.DepositCommision = parseFloat(DepositCommision);

            dispatch(SettingActions.updateSettingRequest(updatedSetting));
        }
    }

    const isValid = () => {
        if (!IsNullOrEmpty(BCHCommision) && parseFloat(BCHCommision) > 0 &&
            !IsNullOrEmpty(BTCCommision) && parseFloat(BTCCommision) > 0 &&
            !IsNullOrEmpty(LTCCommision) && parseFloat(LTCCommision) > 0 &&
            !IsNullOrEmpty(TRXCommision) && parseFloat(TRXCommision) > 0 &&
            !IsNullOrEmpty(XRPCommision) && parseFloat(XRPCommision) > 0 &&
            !IsNullOrEmpty(ETHCommision) && parseFloat(ETHCommision) > 0 &&
            !IsNullOrEmpty(BNBCommision) && parseFloat(BNBCommision) > 0 &&
            !IsNullOrEmpty(BCHMin) && parseFloat(BCHMin) > 0 &&
            !IsNullOrEmpty(BTCMin) && parseFloat(BTCMin) > 0 &&
            !IsNullOrEmpty(LTCMin) && parseFloat(LTCMin) > 0 &&
            !IsNullOrEmpty(TRXMin) && parseFloat(TRXMin) > 0 &&
            !IsNullOrEmpty(XRPMin) && parseFloat(XRPMin) > 0 &&
            !IsNullOrEmpty(ETHMin) && parseFloat(ETHMin) > 0 &&
            !IsNullOrEmpty(BNBMin) && parseFloat(BNBMin) > 0 &&
            !IsNullOrEmpty(WithdrawCommision) && parseFloat(WithdrawCommision) > 0 &&
            !IsNullOrEmpty(DepositCommision) && parseFloat(DepositCommision) > 0
        ) {
            return true;
        }

        return false;
    }

    return (
        <div>
            <NavBar
                pageName="Settings"
                children={
                    <Container maxWidth={false} >
                        <TitleBar heading="Settings" secondary={"Crypto Users and Wallets"}
                            icon={<HelpOutlineIcon />} />
                        <Grid container spacing={2} >
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BCHCommision"}
                                    value={BCHCommision}
                                    label="BCH Commission"
                                    onChange={(e) => {
                                        setBCHCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BCHMin"}
                                    value={BCHMin}
                                    label="BCH Minimum Amount"
                                    onChange={(e) => {
                                        setBCHMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BTCCommision"}
                                    value={BTCCommision}
                                    label="BTC Commission"
                                    onChange={(e) => {
                                        setBTCCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BTCMin"}
                                    value={BTCMin}
                                    label="BTC Minimum Amount"
                                    onChange={(e) => {
                                        setBTCMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"LTCCommision"}
                                    value={LTCCommision}
                                    label="LTC Commission"
                                    onChange={(e) => {
                                        setLTCCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"LTCMin"}
                                    value={LTCMin}
                                    label="LTC Minimum Amount"
                                    onChange={(e) => {
                                        setLTCMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"TRXCommision"}
                                    value={TRXCommision}
                                    label="TRX Commission"
                                    onChange={(e) => {
                                        setTRXCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"TRXMin"}
                                    value={TRXMin}
                                    label="TRX Minimum Amount"
                                    onChange={(e) => {
                                        setTRXMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"XRPCommision"}
                                    value={XRPCommision}
                                    label="XRP Commission"
                                    onChange={(e) => {
                                        setXRPCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"XRPMin"}
                                    value={XRPMin}
                                    label="XRP Minimum Amount"
                                    onChange={(e) => {
                                        setXRPMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"ETHCommision"}
                                    value={ETHCommision}
                                    label="ETH Commission"
                                    onChange={(e) => {
                                        setETHCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"ETHMin"}
                                    value={ETHMin}
                                    label="ETH Minimum Amount"
                                    onChange={(e) => {
                                        setETHMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BNBCommision"}
                                    value={BNBCommision}
                                    label="BNB Commission"
                                    onChange={(e) => {
                                        setBNBCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"BNBMin"}
                                    value={BNBMin}
                                    label="BNB Minimum Amount"
                                    onChange={(e) => {
                                        setBNBMin(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"WithdrawCommision"}
                                    value={WithdrawCommision}
                                    label="Withdraw Commission"
                                    onChange={(e) => {
                                        setWithdrawCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name={"DepositCommision"}
                                    value={DepositCommision}
                                    label="Deposit Commision"
                                    onChange={(e) => {
                                        setDepositCommision(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Button onClick={() => save()} disabled={!isValid()} variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                }
            />
            <Footer />
        </div >
    );
}